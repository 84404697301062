<template>
    <div class="container">
        <div class="service-home-success">
            <p class="title-service-home-success">{{ $t('serviceHomeSuccess.title1')}}</p>
            <p class="subtitle-service-home-success">{{ $t('serviceHomeSuccess.subtitle1')}}</p>
            <blockquote class="bq">{{ $t('serviceHomeSuccess.title2')}}</blockquote>
        </div>
        <div class="section-features fixed-bg1">
            <div class="container">
                <p class="title2 title-border title-section">{{ $t('serviceHomeSuccess.cate')}}</p>
                <div class="row">				
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                             <router-link to="/service-home-success/wide-front-house"><img v-lazy="{src: require('@/assets/home1.svg')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link to="/service-home-success/wide-front-house" class="router-home"><p class="feature-title">{{ $t('serviceHomeSuccess.cate1')}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link to="/service-home-success/narrow-house"><img v-lazy="{src: require('@/assets/home2.svg')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link to="/service-home-success/narrow-house" class="router-home"><p class="feature-title">{{ $t("serviceHomeSuccess.cate2")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link to="/service-home-success/minimal-house"><img v-lazy="{src: require('@/assets/modern-house.svg')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link to="/service-home-success/minimal-house" class="router-home"><p class="feature-title">{{ $t("serviceHomeSuccess.cate3")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link to="/service-home-success/thai-style-house"><img v-lazy="{src: require('@/assets/home-thai.svg')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link to="/service-home-success/thai-style-house" class="router-home"><p class="feature-title">{{ $t("serviceHomeSuccess.cate4")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link to="/service-home-success/coffee-house"><img v-lazy="{src: require('@/assets/coffee-cup.svg')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link to="/service-home-success/coffee-house" class="router-home"><p class="feature-title">{{ $t("serviceHomeSuccess.cate5")}}</p></router-link>
                    </div>
                    <div class="col-sm-3 feature-item">
                        <div class="feature-icon">
                            <router-link to="/service-home-success/etc-house"><img v-lazy="{src: require('@/assets/etc.svg')}" alt="" class="icon-feature"></router-link>
                        </div>
                        <router-link to="/service-home-success/etc-house" class="router-home"><p class="feature-title">{{ $t("serviceHomeSuccess.cate6")}}</p></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        goToTopPage() {
            window.scrollTo(0,0)
        }
    }
}
</script>
<style>
.none-line{
    color: black;
}
.none-line:hover{
    color: black;
    text-decoration: none;
}
</style>